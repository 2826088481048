import axios from 'axios'
import user from '../data/user'
import router from '../router'
import stubs from '../stubs'

const AUTH_SERVICE_URL = process.env.VUE_APP_AUTH_SERVICE_URL
const IAM_SERVICE_URL = process.env.VUE_APP_IAM_SERVICE_URL
const FILESPOT_SERVICE_URL = process.env.VUE_APP_FILESPOT_SERVICE_URL
const PLAYER_URL = process.env.VUE_APP_PLAYER_URL
const RECORDER_SERVICE_URL = process.env.VUE_APP_RECORDER_SERVICE_URL
const DASHBOARD_SERVICE_URL = process.env.VUE_APP_DASHBOARD_API_SERVICE_URL

const CONTAINER_PLACEHOLDER = '{container_id}'
const OWNER_ID = '{owner_id}'
const SUBUSER_ID = '{subuserId}'

const config = {
  login: {
    url: `${AUTH_SERVICE_URL}/token`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  refresh: {
    url: `${IAM_SERVICE_URL}/token/refresh`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  getUserProfile: {
    url: `${IAM_SERVICE_URL}/users/${OWNER_ID}/subusers/${SUBUSER_ID}/profiles`,
    method: 'get'
  },
  getUserData: {
    url: `${IAM_SERVICE_URL}/users/${OWNER_ID}/subusers/${SUBUSER_ID}`
  },
  getUserSettings: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${OWNER_ID}/subusers/${SUBUSER_ID}/settings`
  },
  getUserAccess: {
    url: `${DASHBOARD_SERVICE_URL}/1/account/users/${OWNER_ID}/subusers/${SUBUSER_ID}/access`
  },
  getObjects: {
    url: `${FILESPOT_SERVICE_URL}/container/${OWNER_ID}/object{path}?strong`
  },
  getObject: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${OWNER_ID}/object_id/{id}`
  },
  getComments: {
    url: `${PLAYER_URL}/objects/{idVideo}/comments?from={nextPage}`
  },
  sendComments: {
    url: `${PLAYER_URL}/objects/{idVideo}/comments`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    }
  },
  delComments: {
    url: `${PLAYER_URL}/comments/{idComment}`,
    method: 'delete',
  },
  getLikes: {
    url: `${PLAYER_URL}/objects/{idVideo}/likes-amount`
  },
  getAllLikes: {
    url: `${PLAYER_URL}/likes`
  },
  setLikes: {
    url: `${PLAYER_URL}/objects/{idVideo}/likes`,
    method: 'post',
  },
  delLikes: {
    url: `${PLAYER_URL}/objects/{idVideo}/likes`,
    method: 'delete',
  },
  removeObject: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${OWNER_ID}/object{path}`,
    method: 'delete'
  },
  uploadObject: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${OWNER_ID}/object{path}`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  },
  updateObject: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${CONTAINER_PLACEHOLDER}/object{path}`,
    method: 'put'
  },
  createFolder: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${OWNER_ID}/object{path}?dir`,
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  },
  findObjects: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container/${CONTAINER_PLACEHOLDER}/search`
  },
  getContainer: {
    url: `${FILESPOT_SERVICE_URL}/2/fs/container`
  },
//todo: пЕРЕИМЕНОВАТЬ в рекордеры
  createStream: {
    url: `${RECORDER_SERVICE_URL}/1/${CONTAINER_PLACEHOLDER}`,
    method: 'post'
  },
  getStreams: {
    url: `${RECORDER_SERVICE_URL}/1/${CONTAINER_PLACEHOLDER}`
  },
  getStream: {
    url: `${RECORDER_SERVICE_URL}/1/${CONTAINER_PLACEHOLDER}/{id}`
  },
  startInstantRecord: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_PLACEHOLDER}/{id}/start`,
    method: 'post'
  },
  stopRecord: {
    url: `${RECORDER_SERVICE_URL}/${CONTAINER_PLACEHOLDER}/{streamId}/record/{recordId}/stop`,
    method: 'post'
  },
  getRecord: {
    url: `${RECORDER_SERVICE_URL}/1/${CONTAINER_PLACEHOLDER}/{streamId}/record/{recordId}`
  },
  createRecord: {
    url: `${RECORDER_SERVICE_URL}/1/${CONTAINER_PLACEHOLDER}/{streamId}/record`,
    method: 'post'
  },
  updateRecord: {
    url: `${RECORDER_SERVICE_URL}/1/${CONTAINER_PLACEHOLDER}/{streamId}/record/{recordId}`,
    method: 'put'
  },
  getRecords: {
    url: `${RECORDER_SERVICE_URL}/1/${CONTAINER_PLACEHOLDER}/{streamId}/record`
  },
  removeRecord: {
    url: `${RECORDER_SERVICE_URL}/1/${CONTAINER_PLACEHOLDER}/{streamId}/record/{recordId}`,
    method: 'delete'
  },
}

const handlers = {
  success: [],
  error: []
}

const api = {
  addHandler (handler, type) {
    handlers[type].push(handler)
    return this
  },
  onSuccess (response) {
    let breakChain = false

    handlers.success.forEach(handler => {
      if (handler(response) === false) {
        breakChain = true
      }
    })

    return breakChain ? Promise.resolve({}) : response
  },
  onError (error) {
    let breakChain = false

    handlers.error.forEach(handler => {
      if (handler(error) === false) {
        breakChain = true
      }
    })

    if (breakChain) {
      return Promise.resolve();
    } else {
      throw error
    }
  },
  token: null,
  refreshToken () {
    const token = localStorage.getItem('refresh_token')
    const id = localStorage.getItem('owner_id')

    if (!token || !id) {
      return new Promise((resolve, reject) => {
        reject(new Error('Refresh info is not found'))
      })
    }

    return this.refresh({
      owner_id: id,
      refresh_token: token
    }).then(response => {
      return this.setToken(response.data)
    })
  },
  async setToken (data) {
    this.token = data.access_token

    localStorage.setItem('refresh_token', data.refresh_token)
    localStorage.setItem('owner_id', data.subuser_id || data.user_id)
    console.log("USER1111111111111", data)
    user.id = data.subuser_id || data.user_id
    user.owner_id = data.owner_id || data.user_id
    //user.login =

    const userId = data.owner_id
    const subuserId = user.id
    console.log("userId 9999999", userId)
      return await this.getUserData({ userId, subuserId }).then(({ data }) => {
        console.log("getUserData", data)
        user.login = data.login
        // TODO: проверить нужна ли проверка
        if (!data.id) {
          data.id = data.owner_id
        }

        this.setUserData(data)
      }).catch(err => {
        console.log('ERR: SetToken', err.response)
      })
    },

  setUserData (data) {
    Object.keys(data).forEach((prop) => {
      user[prop] = data[prop]
    })
    console.log("USVER", user)
  },
  logout () {
    console.log("user", user)
    this.token = null
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('owner_id')
    localStorage.removeItem('session_key')
    user.id = null
    this.login({
      login: 'nauth@pc.lab',
      password: 'ABC123abc!!',})
        .then(({ data }) => {
          user.atomic_login = 'nauth@pc.lab',
          localStorage.setItem("atomic", "nauth" )
          this.setToken(data)
          this.$router.push({ name: 'home' })

        }).catch((err) => {
      console.log('Sing in ERR:', err.response)
      // this.$toast.show({
      //   text: 'Неверно введён логин или пароль.',
      //   lifetime: 1000
      // })
    })
    console.log("user2", user)
    router.push({
      name: 'login'
    })
  }
}

Object.keys(config).forEach(function (method) {
  const endpoint = config[method]

  api[method] = function (data, params = {}) {
    const config = Object.assign({}, params, {
      url: endpoint.url,
      method: endpoint.method || 'get',
      headers: endpoint.headers || {}
    })

    if (config.url.match(CONTAINER_PLACEHOLDER)) {
      config.url = config.url.replace(CONTAINER_PLACEHOLDER, user.owner_id)
    }

    if (config.url.match(OWNER_ID)) {
      config.url = config.url.replace(OWNER_ID, user.owner_id)
    }

    if (data instanceof FormData) {
      for (const item of data.entries()) {
        const [key, value] = item
        config.url = config.url.replace('{' + key + '}', () => {
          data.delete(key)
          return value
        })
      }
    } else if (data instanceof Object) {
      Object.keys(data).forEach((item) => {
        config.url = config.url.replace('{' + item + '}', () => {
          const value = data[item]
          delete data[item]
          return value
        })
      })
    }

    if (config.method === 'get') {
      config.params = data
    } else {
      if (data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        const query = new URLSearchParams()

        Object.keys(data).forEach(function (key) {
          query.append(key, data[key])
        })
        config.data = query
      } else {
        config.data = data
      }
    }

    if (endpoint.stub) {
      return new Promise(resolve => {
        const response = stubs[endpoint.url]

        if (typeof response === 'function') {
          resolve({ data: response() })
        } else {
          throw new Error(endpoint.url)
        }
      })
    }

    return request(config)
  }
})

function request (params) {
  if (api.token) {
    params.headers = params.headers || {}
    Object.assign(params.headers, {
      Authorization: `Bearer ${api.token}`
    })
  }

  params.url = encodeURI(params.url)

  return axios.request(params).then(api.onSuccess).catch(api.onError)
}

export default api
