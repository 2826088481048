import { defineStore } from 'pinia';

export const useThemeStore = defineStore('theme', {
    state: () => ({
        theme: localStorage.getItem('theme') || 'light',
    }),
    actions: {
        toggleTheme() {
            this.theme = this.theme === 'light' ? 'dark' : 'light';
            localStorage.setItem('theme', this.theme);
                if (this.theme === 'light') {
                    document.body.classList.remove('dark-theme');
                    document.body.classList.add('light-theme');
                } else {
                    document.body.classList.remove('light-theme');
                    document.body.classList.add('dark-theme');
                }
        },
        setDarkSystemTheme(val: string) {
            this.theme = val === 'dark' ? 'dark' : 'light';
            localStorage.setItem('theme', this.theme);

                if (this.theme === 'light') {
                    document.body.classList.remove('dark-theme');
                    document.body.classList.add('light-theme');
                } else {
                    document.body.classList.remove('light-theme');
                    document.body.classList.add('dark-theme');
                }
        }
    },
});