export default {
  // user
  //ToDo: переименовать user_id во всем проекте в owner_id
  owner_id: undefined, // owner ID
  id: undefined, // user ID
  role: '',
  login: '',
  atomic_login: localStorage.getItem("atomic") || "",
  created_at: '',
  updated_at: '',
  primary_group: null,
  isOwner: false,
  setUserData (userData) {
    this.isOwner = userData.owner_id === userData.id
    Object.keys(userData).forEach((prop) => {
      this[prop] = userData[prop]
    })
  },

  // profile
  profile_id: '',
  profile_user_id: '',
  email: '',
  phone: '',
  full_name: '',
  organization: '',
  timezone: 'MSK',
  setUserProfile (profileData) {
    Object.keys(profileData).forEach((prop) => {
      if (prop === 'owner_id') {
        this.profile_user_id = profileData[prop]
        return
      } if (prop === 'id') {
        this.profile_id = profileData[prop]
        return
      }
      this[prop] = profileData[prop]
    })
  },

  // settings
  filespot: {
    home_dir: {}
  },
  autotranscoding: {
    enabled: false,
    presets: [], // presets_id[]
    del_original: false,
    sftp: true
  },
  private_files: {
    default: false
  },
  setUserSettings (settingsData) {
    Object.keys(settingsData).forEach((prop) => {
      this[prop] = settingsData[prop]
    })
  },

  // available services
  //TODO: уточнить надо ли это
  services: [],
  administrator: false,
  read_only: false,
  setUserAccess (accessData) {
    Object.keys(accessData).forEach((prop) => {
      this[prop] = accessData[prop]
    })
  }
}
