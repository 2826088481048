import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import api, {RefreshRequest, RefreshResponse} from '@/utils/api'

export interface Api {
  token: string;
  refreshToken(): Promise<void>;
  refreshToken(request: RefreshRequest): Promise<RefreshResponse>;
  login(request: any): any;
  setToken(request: any): any;
  // другие методы
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/article/:id',
    name: 'article',
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleView.vue')
  },
  {
    path: '/article',
    name: 'article',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoriesView.vue')
  },
  {
    path: '/live',
    name: 'live',
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoriesView.vue')
  },
  {
    path: '/upload',
    name: 'upload',
    component: () => import(/* webpackChunkName: "about" */ '../views/CategoriesView.vue')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const guestRoute = to.meta.guest
  console.log("guestRoute", guestRoute)
  console.log("to", to)

  if (!guestRoute && to.name !== 'login') {
    if ((api as Api).token) {
      next()
    } else {
      // Тут будет две логики одна для автологина неавторизованного пользователя, другая для обновления токена
      (api as Api).refreshToken().then(() => {
        next()
      }).catch(() => {
        if (to.name !== 'login') {
          //Вот тут и надо логинить nauth
          (api as Api).login({
            login: 'nauth@pc.lab',
            password: 'ABC123abc!!',
          }).then(({ data }: { data: any }) => {
            (api as Api).setToken(data)
            next({
              name: 'home'
            })
          })
          next({
            name: 'login'
          })
        } else {
          next(false)
        }
      })
    }
  } else {
    next()
  }
})


export default router
