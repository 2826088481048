import {computed, createApp, provide, watch} from 'vue';
import App from './App.vue';
import router from './router';
import api from './utils/api';
import { createPinia } from 'pinia';
import './assets/styles/global.css';
import {ref} from "vue";
import  { useThemeStore} from "@/store/theme";
import eventBus from './utils/eventBus.js';

const pinia = createPinia();

const app = createApp(App)
    .use(pinia)
    .use(router);

const themeStore = useThemeStore();
const theme = ref(themeStore.theme);

provide('theme', theme);

watch(theme, (newTheme) => {
    if (newTheme === 'light') {
        document.body.classList.remove('dark-theme');
        document.body.classList.add('light-theme');
    } else {
        document.body.classList.remove('light-theme');
        document.body.classList.add('dark-theme');
    }
});

if (theme.value === 'light') {
    document.body.classList.add('light-theme');
} else {
    document.body.classList.add('dark-theme');
}

// Настройка глобальных свойств ПОСЛЕ подключения плагинов
app.config.globalProperties.$bus = eventBus as any;
app.config.globalProperties.$api = api as any;

// монтируем приложение
app.mount('#app');
