import {ref} from "vue";


export const categoryList = ref({
    news: "Новости",
    interview: "Интервью",
    podcasts: "Подкасты",
    tech: "Технологии"
});

export const NONAUTH_USER = "__ldap__nonauth_user__users";
export const MODERATOR_USER = "__ldap__moderator_vod__users";
export const ADMIN_USER = "__ldap__admin__users";